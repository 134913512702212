<template>
	<b-modal
		id="push-pull"
		ref="push-pull"
		:title="title"
		@ok="handleOk()"
		ok-title="Proceed"
		:cancel-disabled="disableConfirmButtons"
		:ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true"
	>
		<loading
			:active.sync="isLoading"
			loader="spinner"
			color="#20A8D8"
			:is-full-page="false"
		/>

		<div class="confirmMessage">
			<b-row class="my-3">
				<b-col sm="12">
					You are about to create a new dispatch to return the same set of
					assets from
					<b>{{ newSourceCompany }}</b> to <b>{{ newDestinationCompany }}</b>
					?
				</b-col>
			</b-row>
		</div>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { DispatchUtil } from '@/utils/dispatchUtil';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'PushPullDialog',
	components: {
		Loading,
	},
	data() {
		return {
			selDispatch: {},
			recipient: '',
			loggedUser: this.$store.getters.loggedUser,
			dateReceived: DateUtil.getCurrentTimestamp(),

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		title() {
			return 'Push Pull';
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
		newSourceCompany() {
			let newSourceCompany = '';
			if (
				this.selDispatch &&
				this.selDispatch.destination &&
				this.selDispatch.destination.company
			) {
				newSourceCompany = this.selDispatch.destination.company;
			}
			return newSourceCompany;
		},
		newDestinationCompany() {
			let newDestinationCompany = '';
			if (
				this.selDispatch &&
				this.selDispatch.source &&
				this.selDispatch.source.company
			) {
				newDestinationCompany = this.selDispatch.source.company;
			}
			return newDestinationCompany;
		},
	},
	mounted() {
		EventBus.$on('onUpdateSelDispatch', (selDispatch) => {
			if (!_.isEmpty(selDispatch)) {
				this.selDispatch = DispatchUtil.cleanupFields(selDispatch);
			}
		});
	},
	methods: {
		handleOk() {
			EventBus.$emit('redirectToDispatch', this.selDispatch);
		},
	},
	beforeDestroy() {
		EventBus.$off('onUpdateSelDispatch');
	},
};
</script>

