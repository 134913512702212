<template>
	<b-modal
		id="receive-dispatch"
		ref="receive-dispatch"
		:title="title"
		@show="onReset"
		ok-title="Save"
		@ok="handleOk"
		:cancel-disabled="disableConfirmButtons"
		:ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true"
	>
		<loading
			:active.sync="isLoading"
			loader="spinner"
			color="#20A8D8"
			:is-full-page="false"
		/>
		<div class="confirmMessage">
			<b-row class="my-3">
				<b-col sm="12">
					Proceed receiving
					<b>{{ selDispatch.dispatchId }}</b> for
					<b>{{ recipient }}</b> company? &nbsp;
					<br />
					<i>(Specify date received then choose option below)</i>
				</b-col>
			</b-row>
			<b-row class="my-3">
				<b-col sm="6">
					<b-form-group label="Date Received">
						<date-range-picker
							opens="center"
							:single-date-picker="true"
							:timePicker="true"
							:timePicker24Hour="false"
							v-model="dateReceived"
							applyLabel="Apply"
							cancelLabel="Cancel"
							:style="{ width: '100%' }"
						>
							<div slot="input">{{ dateReceivedDisplay }}</div>
						</date-range-picker>
					</b-form-group>
				</b-col>
			</b-row>
		</div>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';

// API
import dispatchApi from '@/api/dispatchApi';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';

export default {
	name: 'receive-dispatch',
	components: {
		Loading,
		DateRangePicker,
	},
	data() {
		return {
			selDispatch: {},
			recipient: '',
			loggedUser: this.$store.getters.loggedUser,
			dateReceived: DateUtil.getCurrentTimestamp(),

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		title() {
			return 'Receive Dispatch';
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
		dateReceivedDisplay: {
			get() {
				return moment(this.dateReceived.startDate).format(
					'MMMM D YYYY, h:mm a'
				);
			},
			set(value) {
				this.dateReceived = value.startDate;
			},
		},
		timeStampedReceivedDate() {
			return moment(this.dateReceived.startDate).valueOf();
		},
	},
	mounted() {
		EventBus.$on('onReceiveSelDispatch', (param) => {
			if (this.$refs['receive-dispatch']) {
				this.$refs['receive-dispatch'].show();
			}
			this.selDispatch = param.item;
			this.recipient = this.selDispatch.destination.company;
		});
	},
	methods: {
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			// set the actual assets to have the expected assets for now
			// TODO implement a UI for receiving assets based on the actual assets received
			this.selDispatch.actualAssets = this.selDispatch.assets;

			// No 1 up 1 down by default
			this.selDispatch.apply1Up1Down = false;

			let dispatchId = this.selDispatch.dispatchId;
			try {
				let { data } = await dispatchApi.receiveDispatch(
					this.selDispatch,
					this.loggedUser.id,
					this.timeStampedReceivedDate
				);

				if (data.isSuccess) {
					this.$toaster.success(
						`Dispatch "${this.selDispatch.dispatchId}" was received successfully.`
					);
					this.$refs['receive-dispatch'].hide();
					EventBus.$emit('onCloseReceiveDispatch', data.dispatch);
				} else {
					// hide loading indicator
					this.isLoading = false;
					this.$toaster.error(data.message);
				}
			} catch (error) {
				// hide loading indicator
				this.isLoading = false;
				this.$toaster.error(
					`Error receiving dispatch ${dispatchId}. Please try again.`
				);
			}
		},
		onReset() {
			this.dateReceived = DateUtil.getCurrentTimestamp();
		},
	},
	beforeDestroy() {
		EventBus.$off('onReceiveSelDispatch');
	},
};
</script>

