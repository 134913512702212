<template>
	<div class="animated fadeIn">
		<b-card title="Receipt" sub-title="Manages the incoming dispatches for all companies">
			<b-container fluid class="mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<!-- Filter  -->
				<b-row class="mt-2">
					<b-col sm="12" md="3" lg="3">
						<b-button v-b-popover.hover.right="'Toogle to show/hide filter options'" v-b-toggle.collapse-1
							class="filter">
							FILTER OPTIONS
						</b-button>
					</b-col>
					<b-col sm="12">
						<!-- Collapsible Filter Options -->
						<b-collapse id="collapse-1" class="mt-2">
							<b-card>
								<b-row no-gutters>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Date From">
											<b-form-datepicker v-model="filterBy.dateFrom" locale="en" reset-button
												label-reset-button="Clear" :date-format-options="{
													year: 'numeric',
													month: 'short',
													day: '2-digit',
													weekday: 'short',
												}" :date-disabled-fn="dateFromDisabled" />
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Date To">
											<b-form-datepicker v-model="filterBy.dateTo" locale="en" reset-button
												label-reset-button="Clear" :date-format-options="{
													year: 'numeric',
													month: 'short',
													day: '2-digit',
													weekday: 'short',
												}" :date-disabled-fn="dateFromDisabled" />
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Source Company">
											<v-select class="style-chooser" label="text" :options="allSourceCompanyOptions"
												:reduce="(company) => company.value" v-model="filterBy.sourceCompany">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Destination Company">
											<v-select class="style-chooser" label="text"
												:options="allDestinationCompanyOptions" :reduce="(company) => company.value"
												v-model="filterBy.destinationCompany">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a Company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Status">
											<b-form-select v-model="filterBy.status" :options="statusOptions"
												class="mr-2" />
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Dispatch Id">
											<b-form-input id="dispatchId" name="Dispatch Id" type="search"
												v-model="filterBy.dispatchId"
												v-validate="{ regex: /^([a-zA-Z0-9\-])*$/ }" />
											<span v-show="errors.has('Dispatch Id')" class="help-block">{{
												errors.first('Dispatch Id')
											}}</span>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Plate No/CR/OR">
											<v-select class="style-chooser" label="text" :options="plateNoOptions"
												:reduce="(company) => company.value" v-model="filterBy.plateNo">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12" class="mr-2">
										<b-form-group label="Asset Code">
											<b-form-input id="assetCode" name="Asset Code" type="search"
												v-model="filterBy.assetCode" v-validate="{ regex: /^([a-zA-Z0-9\-])*$/ }" />
											<span v-show="errors.has('Asset Code')" class="help-block">{{
												errors.first('Asset Code')
											}}</span>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col sm="12">
										<b-button class="mr-1" variant="success" @click="onFilterRequest">
											Search
										</b-button>
										<b-button class="mr-1" variant="primary" @click="resetFilters">
											Reset
										</b-button>
									</b-col>
								</b-row>
							</b-card>
						</b-collapse>
					</b-col>
				</b-row>

				<!-- Select Actions and Items Per Page Options -->
				<b-row>
					<b-col sm="6" md="3" class="mt-4 mb-2">
						<b-dropdown text=" Select Actions " variant="dark" slot="append">
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="xls" :name="fileName + '.xls'">
									Export Dispatches in Excel
								</json-excel>
							</b-dropdown-item>
							<b-dropdown-item>
								<json-excel :data="exportData" :fields="exportFields" type="csv" :name="fileName + '.csv'">
									Export Dispatches to CSV
								</json-excel>
							</b-dropdown-item>
						</b-dropdown>
					</b-col>
					<b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>

				<b-table show-empty striped hover :items="items" :fields="fields" responsive>
					<template v-slot:cell(source)="row">
						<span class="location-display">
							{{ row.item.source.company }}
							<br />
							({{ row.item.source.storageLocation }})
						</span>
					</template>

					<template v-slot:cell(destination)="row">
						<span class="location-display">
							{{ row.item.destination.company }}
							<br />
							({{ row.item.destination.storageLocation }})
						</span>
					</template>

					<template v-slot:cell(quantity)="row">
						{{ row.item.assets.length }}
					</template>

					<template v-slot:cell(dateCreated)="row">{{
						getDisplayDateTime(row.item.dateCreated)
					}}</template>

					<template v-slot:cell(dateReceived)="row">{{
						row.item.dateReceived
						? getDisplayDateTime(row.item.dateReceived)
						: '-'
					}}</template>

					<template v-slot:cell(status)="row">
						<DispatchRowStatus :row="row" />
					</template>

					<template v-slot:cell(actions)="row">
						<DispatchRowActions :row="row" :isSuperAdmin="isSuperAdmin" :isViewer="isViewer"
							parentComponent="Receipt" />
					</template>

					<template slot="row-details" slot-scope="row">
						<DispatchDetailsView :row="row" :allUsersObj="allUsersObj" />
					</template>
				</b-table>

				<b-row>
					<b-col md="8" sm="12" class="my-1">
						<span class="totalDisplay">Total: {{ totalRows }}</span>
					</b-col>
					<b-col md="4" sm="12" class="my-1">
						<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
							class="my-0" />
					</b-col>
				</b-row>
			</b-container>
		</b-card>

		<!-- Modals here -->
		<ApproveDispatch />
		<ReceiveDispatch />
		<ReceiveDispatchWith1Up1Down />
		<ScannedAssetsView />
		<DispatchLocationView />
		<PushPullDialog />
		<PrintDispatchSummary />
		<ProofReceiptImageLocationView />
		<ProofReceiptImageView />
		<DamagedAssetView />
		<DriversLicenseImageView />
	</div>
</template>

<script>
// Components
import DispatchRowStatus from '@/views/transactions/dispatch/DispatchRowStatus';
import DispatchRowActions from '@/views/transactions/dispatch/DispatchRowActions';
import ApproveDispatch from '@/views/transactions/dispatch/ApproveDispatch';
import ReceiveDispatch from '@/views/transactions/dispatch/ReceiveDispatch';
import ReceiveDispatchWith1Up1Down from '@/views/transactions/dispatch/ReceiveDispatchWith1Up1Down';
import ScannedAssetsView from '@/views/transactions/dispatch/ScannedAssetsView';
import DispatchLocationView from '@/views/transactions/dispatch/DispatchLocationView';
import DispatchDetailsView from '@/views/transactions/dispatch/DispatchDetailsView';
import PushPullDialog from '@/views/transactions/dispatch/PushPullConfirmationDialog';
import PrintDispatchSummary from '@/views/transactions/dispatch/PrintDispatchSummary';
import ProofReceiptImageLocationView from '@/views/transactions/dispatch/ProofReceiptImageLocationView';
import ProofReceiptImageView from '@/views/transactions/dispatch/ProofReceiptImageView';
import DamagedAssetView from '@/views/transactions/dispatch/DamagedAssetView';
import DriversLicenseImageView from '@/views/transactions/dispatch/DriversLicenseImageView';

// Util
import { DateUtil } from '@/utils/dateutil';
import { ValidationUtil } from '@/utils/validationUtil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { LocationUtil } from '@/utils/locationUtil';
import { DispatchUtil } from '@/utils/dispatchUtil';

// API
import dispatchApi from '@/api/dispatchApi';

// DAO
import dispatchDAO from '@/database/dispatches';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';
import JsonExcel from 'vue-json-excel';
import _ from 'lodash';

export default {
	name: 'receipt',
	components: {
		DispatchRowStatus,
		DispatchRowActions,
		ApproveDispatch,
		ReceiveDispatch,
		ReceiveDispatchWith1Up1Down,
		ScannedAssetsView,
		DispatchLocationView,
		DispatchDetailsView,
		PrintDispatchSummary,
		ProofReceiptImageLocationView,
		ProofReceiptImageView,
		DriversLicenseImageView,
		PushPullDialog,
		DamagedAssetView,
		Loading,
		JsonExcel,
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'dispatchId',
					sortable: true,
				},
				{
					key: 'dateDeployed',
				},
				{
					key: 'dateReceived',
				},
				{
					key: 'status',
				},
				'source',
				'destination',
				{
					key: 'Deployed Quantity',
					label: 'Deployed Assets',
				},
				{
					key: 'Received Quantity',
					label: 'Received Assets',
				},
				'actions',
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			defaultFilterBy: {
				dateFrom: moment().format('YYYY-MM-DD'),
				dateTo: moment().format('YYYY-MM-DD'),
				sourceCompany: config.companyDefaultValue,
				destinationCompany: config.companyDefaultValue,
				status: 'In-Transit',
				dispatchId: '',
				assetCode: '',
				plateNo: config.transportationDefaultValue,
			},
			filterBy: {
				dateFrom: moment().format('YYYY-MM-DD'),
				dateTo: moment().format('YYYY-MM-DD'),
				sourceCompany: config.companyDefaultValue,
				destinationCompany: config.companyDefaultValue,
				status: 'In-Transit',
				dispatchId: '',
				assetCode: '',
				plateNo: config.transportationDefaultValue,
			},
			prevFilterBy: {},

			allSourceCompanyOptions: [],
			allDestinationCompanyOptions: [],
			statusOptions: config.receiptStatus,
			plateNoOptions: [],

			allCompaniesObj: {},
			allConnectedCompaniesObj: {},
			allStorageLocationsObj: {},
			allConnectedStorageLocationsObj: {},
			allConnectionsObj: {},
			allUsersObj: {},
			allTransportationsObj: {},
			allAssetTypesObj: {},
			allDispatchesObj: {},

			selDispatch: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			isViewer: this.$store.getters.isViewer,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			loggedUser: this.$store.getters.loggedUser,
			// Check for loader
			isLoading: false,

			// Listener
			dispatchListener: null,
		};
	},
	computed: {
		/**
		 * Returns the set of data to be included in the export. For now this just
		 * returns the data as is.
		 *
		 * @returns {Array} the set of data to be included in the export.
		 */
		exportData() {
			return this.items;
		},

		/**
		 * Derives the field information based from the data table configuration.
		 *
		 * @returns {object} the fields to be included in the export.
		 */
		exportFields() {
			return {
				'Dispatch ID': 'dispatchId',
				'Date Deployed': 'Date Deployed',
				'Date Received': 'Date Received',
				Status: 'status',
				Source: 'Source',
				Destination: 'Destination',
				'Deployed Assets': 'Deployed Assets',
				'Deployed Quantity': 'Deployed Quantity',
				'Received Assets': 'Received Assets',
				'Received Quantity': 'Received Quantity',
				'Lost Assets': 'Lost Assets',
				'Lost Quantity': 'Lost Quantity',
				'Added Received Assets': 'Added Received Assets',
				'Added Received Quantity': 'Added Received Quantity',
				'Truck Company': 'transportation.company',
				Driver: 'Truck Driver',
				'Plate No/CR/OR': 'transportation.plateNo',
				'Date Created': 'Date Created',
				'Date Updated': 'Date Updated',
				'Date Cancelled': 'Date Cancelled',
				Notes: 'notes',
			};
		},

		fileName() {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return 'Receipt-' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
		},
	},
	mounted() {
		setTimeout(async () => {
			try {
				// Don't initiate data retrieval when the account is not authenticated
				if (!this.$store.getters.isAuthenticated) {
					return;
				}

				// show loading indicator
				this.isLoading = true;

				this.updateParams();

				await this.retrieveData();
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}

			// hide loading indicator
			this.isLoading = false;
		}, config.timeout);

		// Event Listeners
		EventBus.$on('onCloseReceiveDispatch', (dispatchObj) => {
			this.updateTable(dispatchObj);
		});

		EventBus.$on('redirectToDispatch', (dispatchObj) => {
			this.redirectToDispatch(dispatchObj);
		});
	},
	methods: {

		listenerCallback(type, dispatch) {
			if ((!this.allDispatchesObj[dispatch.id] && type === "added") || type === "modified") {
				this.allDispatchesObj[dispatch.id] = dispatch;
				this.processDispatches(this.allDispatchesObj);
			}
		},

		updateParams() {
			this.allCompaniesObj = this.$store.getters.companies;
			this.allConnectedCompaniesObj = this.$store.getters.connectedCompanies;
			this.allStorageLocationsObj = this.$store.getters.storageLocations;
			this.allConnectedStorageLocationsObj =
				this.$store.getters.connectedStorageLocations;
			this.allConnectionsObj = this.$store.getters.connections;
			this.allUsersObj = this.$store.getters.users;
			this.allTransportationsObj = this.$store.getters.transportations;
			this.allAssetTypesObj = this.$store.getters.assetTypes;

			// Create Source and Destination Company Options
			if (this.isSuperAdmin) {
				this.allSourceCompanyOptions = DropDownItemsUtil.retrieveCompanyItems(
					this.allCompaniesObj, this.loggedUserCompany
				);
				this.allDestinationCompanyOptions =
					DropDownItemsUtil.retrieveCompanyItems(this.allCompaniesObj, this.loggedUserCompany);
			} else {
				this.allSourceCompanyOptions = DropDownItemsUtil.retrieveCompanyItems(
					this.allConnectedCompaniesObj, this.loggedUserCompany
				);
				this.allDestinationCompanyOptions =
					DropDownItemsUtil.retrieveCompanyItems(this.allCompaniesObj, this.loggedUserCompany);

				this.allCompaniesObj = Object.assign(
					{},
					this.allCompaniesObj,
					this.allConnectedCompaniesObj
				);

				this.allStorageLocationsObj = Object.assign(
					{},
					this.allStorageLocationsObj,
					this.allConnectedStorageLocationsObj
				);

				// Select Logged Company By Default for Destination Company
				this.filterBy.destinationCompany = DropDownItemsUtil.getCompanyItem(
					this.loggedUserCompany
				);
				this.defaultFilterBy.destinationCompany = DropDownItemsUtil.getCompanyItem(
					this.loggedUserCompany
				);
			}

			// parse geoaddress of storage location
			_.forEach(this.allStorageLocationsObj, function (value, _key) {
				value.geoaddress = LocationUtil.getGeoaddress(value.geoaddress);
			});

			// Create Transportations Options
			this.plateNoOptions = DropDownItemsUtil.retrievePlateNoItems(
				this.allTransportationsObj
			);
		},
		updateTable(dispatchObj) {
			if (_.isEmpty(dispatchObj)) {
				return;
			}
			this.allDispatchesObj[dispatchObj.id] = dispatchObj;

			let filteredObjs = Object.assign({}, this.allDispatchesObj);
			_.forEach(this.allDispatchesObj, (dispatch, id) => {
				this.filterBySourceAndDestination(filteredObjs, dispatch, id);

				let status = this.filterBy.status;
				if (status && status.length > 0 && status !== dispatch.status) {
					delete filteredObjs[id];
				}

				let dispatchId = this.filterBy.dispatchId;
				if (
					dispatchId &&
					dispatchId.length > 0 &&
					dispatchId !== dispatch.dispatchId
				) {
					delete filteredObjs[id];
				}

				let plateNo = this.filterBy.plateNo;
				if (
					plateNo &&
					plateNo.length > 0 &&
					plateNo !== dispatch.transportation.plateNo
				) {
					delete filteredObjs[id];
				}

				let assetCode = this.filterBy.assetCode;
				if (
					assetCode &&
					assetCode.length > 0 &&
					!dispatch.assets.includes(assetCode)
				) {
					delete filteredObjs[id];
				}
			});

			this.processDispatches(filteredObjs);
		},
		filterBySourceAndDestination(filteredObjs, dispatch, id) {
			let sourceCompanyId = this.filterBy.sourceCompany.id;
			if (
				!_.isEmpty(sourceCompanyId) &&
				sourceCompanyId !== dispatch.source.companyId
			) {
				delete filteredObjs[id];
			}

			let destinationCompanyId = this.filterBy.destinationCompany.id;
			if (
				!_.isEmpty(destinationCompanyId) &&
				destinationCompanyId !== dispatch.destination.companyId
			) {
				delete filteredObjs[id];
			}
		},

		dateFromDisabled(_ymd, date) {
			return date > new Date();
		},
		isValidAssetCode(assetCode) {
			let assetTypesObj = this.$store.getters.assetTypes;
			return (
				_.isEmpty(assetCode) ||
				ValidationUtil.isValidAssetCode(assetTypesObj, assetCode)
			);
		},
		validateFilter() {
			let isValid = true;

			if (this.filterBy.dateFrom > this.filterBy.dateTo) {
				this.$toaster.warning(
					'Invalid Date Range. Date From must be less than Date To.'
				);
				isValid = false;
			} else if (!this.isSuperAdmin && !this.filterBy.destinationCompany.id) {
				this.$toaster.warning(
					'Destination Company is required.'
				);
				isValid = false;
			} else if (!this.isValidAssetCode(this.filterBy.assetCode)) {
				this.$toaster.warning(
					`Invalid Asset Code. "${this.filterBy.assetCode}" doesn't follow any of your asset tagging format.`
				);
				isValid = false;
			} else if (!ValidationUtil.isAlphaNumeric(this.filterBy.dispatchId)) {
				this.$toaster.warning(
					'Invalid Dispatch ID. Please enter a valid Dispatch ID'
				);
				isValid = false;
			}
			return isValid;
		},
		onFilterRequest() {
			if (!this.validateFilter()) {
				return;
			}

			if (!_.isEqual(this.filterBy, this.prevFilter)) {
				this.retrieveData();
				this.prevFilter = Object.assign({}, this.filterBy);
			}
		},
		resetFilters() {
			if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
				// reset to default
				this.filterBy = Object.assign({}, this.defaultFilterBy);
				this.retrieveData();
				this.prevFilter = Object.assign({}, this.filterBy);
			}
		},
		async retrieveData() {
			try {
				// show loading indicator
				this.isLoading = true;

				let filter = Object.assign({}, this.filterBy);
				filter.source = 'Receipt';
				filter.fromTimestamp = DateUtil.startDateTimeStamp(
					new Date(filter.dateFrom)
				);
				filter.toTimestamp = DateUtil.endDateTimeStamp(new Date(filter.dateTo));

				if (this.isSuperAdmin) {
					filter.companyId = this.loggedUserCompany.id;
					const { data } = await dispatchApi.getDispatches(
						filter,
						config.view.ADMIN,
						this.loggedUser.id
					);
					this.allDispatchesObj = data.dispatches;
				} else {
					filter.companyId = this.loggedUserCompany.id;
					const { data } = await dispatchApi.getDispatches(
						filter,
						config.view.COMPANY,
						this.loggedUser.id
					);
					this.allDispatchesObj = data.dispatches;
				}

				this.processDispatches(this.allDispatchesObj);

				// update listener
				this.dispatchListener = dispatchDAO.getDispatchListener(filter, this.listenerCallback);
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}

			// hide loading indicator
			this.isLoading = false;
		},
		processDispatches(dispatches) {
			this.items = Object.values(dispatches);
			this.items = _.sortBy(this.items, ['dateCreated']);
			this.items.reverse();

			this.items.forEach((item) => {
				// Parse source and destination display
				item['Source'] = DispatchUtil.getCompanyLocationDisplay(item.source);
				item['Destination'] = DispatchUtil.getCompanyLocationDisplay(
					item.destination
				);

				item['Truck Driver'] = item.driver.name;
				item['Truck Assistant'] = item.driver.assistants;

				// Parse deployed assets
				item['Deployed Assets'] = [];
				item['Deployed Quantity'] = 0;
				if (item.assets && item.assets.length > 0) {
					item['Deployed Assets'] = _.difference(
						item.assets,
						item.addedExpectedAssets
					);
					item['Deployed Quantity'] = item['Deployed Assets'].length;
				}

				// Parse received assets
				item['Received Assets'] = [];
				item['Received Quantity'] = 0;
				if (item.actualAssets && item.actualAssets.length > 0) {
					item['Received Assets'] = item.actualAssets;
					item['Received Quantity'] = item.actualAssets.length;
				}

				// Parse lost assets
				item['Lost Assets'] = [];
				item['Lost Quantity'] = 0;
				if (item.status === 'Received') {
					item['Lost Assets'] = _.difference(item.assets, item.actualAssets);
					item['Lost Quantity'] = item['Lost Assets'].length;
				}

				// Parse added expected assets
				item['Added Received Assets'] = [];
				item['Added Received Quantity'] = 0;
				if (item.addedExpectedAssets && item.addedExpectedAssets.length > 0) {
					item['Added Received Assets'] = item.addedExpectedAssets;
					item['Added Received Quantity'] = item.addedExpectedAssets.length;
				}

				// Parse timestamps
				item['Date Created'] = this.getDisplayDateTime(item.dateCreated);
				item['Date Updated'] = this.getDisplayDateTime(item.dateUpdated);
				item['Date Deployed'] = this.getDisplayDateTime(item.dateDeployed);
				item['Date Received'] = this.getDisplayDateTime(item.dateReceived);
				item['Date Cancelled'] = this.getDisplayDateTime(item.dateCancelled);

				// make sure the details section are all closed by default
				if (item._showDetails && item._showDetails === true) {
					item._showDetails = false;
				}
			});
			this.totalRows = this.items.length;
			this.$store.dispatch('setAllDispatches', this.allDispatchesObj);
		},
		async retrieveDamagedAssets() {
			let damagedAssets = [];
			let assetsObjResult = await this.$store.dispatch('getAssetsByAssetCode', {
				assetCodes: this.selDispatch.actualAssets,
			});
			let assetsArr = Object.values(assetsObjResult[0]);
			assetsArr.forEach((asset) => {
				let assetCode = asset.assetCode;
				if (asset.condition && asset.condition === 'Damaged') {
					damagedAssets.push(assetCode);
				}
			});

			return damagedAssets;
		},

		redirectToDispatch(item) {
			let params = {
				allCompaniesObj: this.allCompaniesObj,
				allStorageLocationsObj: this.allStorageLocationsObj,
				allConnectionsObj: this.allConnectionsObj,
				allUsersObj: this.allUsersObj,
				allTransportationsObj: this.allTransportationsObj,
				allAssetTypesObj: this.allAssetTypesObj,
				allDispatchesObj: this.allDispatchesObj,
			};

			// redirect to Add Dispatch page
			this.$store.dispatch('setDispatchParams', params);
			this.$store.dispatch('setCurrentDispatch', item);

			if (this.isSuperAdmin) {
				this.$router.push({ path: '/admin/admin-add-dispatch' });
			} else {
				this.$router.push({ path: '/add-dispatch' });
			}
		},
		async updateSelDispatch(item) {
			this.selDispatch = item;

			// update the actual assets temporarily for validation
			this.selDispatch.actualAssets = this.selDispatch.assets;

			this.$store.commit('SET_CURR_DISPATCH', this.selDispatch);

			// Show Loader
			this.isLoading = true;

			// Assign default asset condition on each actual assets to receive
			// (Since we don't have UI yet for receiving and assigning asset condition in web we will retrieve existing condition of assets from the database)
			// TODO create UI for assigning condition of each asset before validating receive
			this.selDispatch.damagedAssets = await this.retrieveDamagedAssets(
				this.selDispatch.actualAssets
			);

			let { data } = await dispatchApi.validateReceive(
				this.selDispatch,
				this.loggedUser.id
			);

			let has1Up1Down = false;
			let unmatchedAssets = [];
			if (data.isSuccess && data.has1Up1Down) {
				has1Up1Down = true;
				unmatchedAssets = data.unmatchedAssets;

				this.selDispatch.apply1Up1Down = has1Up1Down;
				this.receiveWith1Up1Down(this.selDispatch, unmatchedAssets);
			} else {
				this.receiveWithout1Up1Down({
					item: this.selDispatch,
					has1Up1Down: has1Up1Down,
					unmatchedAssets: unmatchedAssets,
				});
			}

			// Hide Loader
			this.isLoading = false;
		},
		receiveWith1Up1Down(dispatch, unmatchedAssets) {
			// active to active
			EventBus.$emit('onReceiveSelDispatchWith1Up1Down', [
				dispatch,
				unmatchedAssets,
			]);
		},
		receiveWithout1Up1Down(item) {
			let fromInactiveNode = this.selDispatch.fromInactiveNode;
			let toInactiveNode = this.selDispatch.toInactiveNode;
			if (toInactiveNode && toInactiveNode === 'true') {
				// active to inactive
				EventBus.$emit('onApproveSelDispatch', item);
			} else if (fromInactiveNode && fromInactiveNode === 'true') {
				// inactive to active
				EventBus.$emit('onApproveSelDispatch', item);
			} else {
				// active to active
				EventBus.$emit('onReceiveSelDispatch', item);
			}
		},

		// UTILS
		getDisplayDateTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
	},

	beforeUnmount() {
		if (this.dispatchListener != null) {
			// Stop listening to changes
			this.dispatchListener();
		}
	},

	beforeDestroy() {
		EventBus.$off('onCloseReceiveDispatch');
		EventBus.$off('redirectToDispatch');
	},
};
</script>
